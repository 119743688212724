<template>
  <div>
    <b-card v-if="croppedImage" no-body>
      <img class="img-fluid" :src="croppedImage" />
      <div class="d-flex justify-content-end">
        <b-button
          size="sm"
          variant="link"
          class="text-danger"
          @click="onRemove"
        >
          <span class="fas fa-trash" /> Remover
        </b-button>
      </div>
    </b-card>
    <span v-else>
      <b-form-file
        :state="validation.image"
        placeholder="Clique ou arraste uma imagem aqui..."
        drop-placeholder="Arraste uma imagem..."
        @change="onChange"
      />
      <b-form-invalid-feedback class="text-danger">
        Inclua uma imagem
      </b-form-invalid-feedback>
    </span>
    <ImageCropperModal
      v-model="isModalOpen"
      :image="image"
      :mime-type="mimeType"
      @ok="onOk"
    />
  </div>
</template>

<script>
import ImageCropperModal from './ImageCropperModal'

export default {
  name: 'ImageInput',
  comments: {
    ImageCropperModal
  },
  components: { ImageCropperModal },
  props: {
    value: {
      type: [Blob, String],
      default: null
    },
    validation: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  data() {
    return {
      isModalOpen: false,
      croppedImage: '',
      image: null,
      mimeType: null
    }
  },
  computed: {
    model: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  watch: {
    value: {
      handler(value) {
        if (typeof value === 'string') {
          this.croppedImage = value
        }
      },
      immediate: true
    }
  },
  methods: {
    onChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.mimeType = file.type

        if (typeof FileReader === 'function') {
          const reader = new FileReader()

          reader.onload = (event) => {
            this.image = event.target.result
            this.isModalOpen = true
          }

          reader.readAsDataURL(file)
        } else {
          alert('FileReader API is not supported')
        }
      }
    },
    onOk(blob) {
      this.model = blob
      var URLCreator = window.URL || window.webkitURL
      this.croppedImage = URLCreator.createObjectURL(blob)
    },
    onRemove() {
      this.croppedImage = null
      this.model = null
    }
  }
}
</script>
