<template>
  <b-modal
    v-model="open"
    title="Cortar Imagem"
    no-close-on-backdrop
    @ok="onOk"
    @cancel="onCancel"
    @close="onClose"
  >
    <vue-cropper
      ref="cropper"
      :src="image"
      :aspect-ratio="16 / 10"
      @ready="onReady"
    >
    </vue-cropper>
  </b-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageCropperModal',
  components: {
    VueCropper
  },
  props: {
    value: {
      type: Boolean
    },
    image: {
      type: String,
      default: null
    },
    mimeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  computed: {
    open: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    onReady() {
      this.isReady = true
    },
    onOk() {
      this.$refs.cropper
        .getCroppedCanvas()
        .toBlob((blob) => this.$emit('ok', blob), this.mimeType)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onClose() {
      this.$emit('cancel')
    }
  }
}
</script>
